




































































































main {
  margin-top: 8rem;
}
.hover {
  color: black;
}
.caseItem {
  // background-color: #fff;
  // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  // padding: 1.5rem !important;
  // // margin: 0;
  // margin-bottom: 20px;
  // min-height: 460px;
  // position: relative;
  // overflow: hidden;
  // cursor: pointer;
  position: relative;
  text-align: center;
  padding-top: 60px;
  // padding-bottom: 20px;
  border-bottom: 1px solid #c9c9c9;
  height: 100%;
  overflow: hidden;
  & > * {
    margin-bottom: 10px;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }
  .content {
    text-indent: 2rem;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }
  .img {
    border: 1px solid #e2e2e2;
    overflow: hidden;
    height: 50%;
    // width: calc(100% - 3rem);
    // position: absolute;
    // bottom: 1.5rem;
    // left: 1.5rem;
    // right: 1.5rem;
    // overflow: hidden;
    // height: 236px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    img {
      width: 100%;
      transition: 2s;
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    border-bottom: 2px solid #409EFF;
    transition: 1s;
  }
  &::after {
    left: auto;
    right: 0;
  }
  &:hover {
    // transform: scale(1.3) rotate(4deg);
    // transform: scale(1.3);
    // transition: ;
    &::before,
    &::after {
      width: 100%;
    }
    .img {
      img {
        transform: scale(1.2);
      }
    }
  }
}
